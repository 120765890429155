import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import pack from '../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: "https://923db5eb6f6b4440babff484d3286bf8@sentry.io/1384145",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["https://app.beefeed.de"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: "beefeed-app@" + pack.version,
    tracesSampleRate: 1.0,
  });
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
