import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, ReplaySubject } from "rxjs";
import * as Sentry from '@sentry/browser';
import { v4 as uuid } from '../../node_modules/uuid';
import { User } from './models/user.model';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Tax } from "./models/product.model";
import { ToastService } from "./ui-components/toast.service";

export interface mainClasses {
  conversations,
  pricelists,
  product_Users,
  users,
  orders,
  shopping_cart_news,
  news,
  unitprices,
  friendships,
  friendshipsByUserId,

  categories,
  productclasses,
  products,
  productclassesByCategory,
  productsByProductclass
}

export class Constants {

  // public static weekDays = [
  //   {
  //     id: 'mo',
  //     name: 'Montag',
  //   },
  //   {
  //     id: 'di',
  //     name: 'Dienstag',
  //   },
  //   {
  //     id: 'mi',
  //     name: 'Mittwoch',
  //   },
  //   {
  //     id: 'do',
  //     name: 'Donnerstag',
  //   },
  //   {
  //     id: 'fr',
  //     name: 'Freitag',
  //   },
  // ]

  // public static weekDaysMap = {
  //   'mo': {
  //     name: 'Montag',
  //   },
  //   'di': {
  //     name: 'Dienstag',
  //   },
  //   'mi': {
  //     name: 'Mittwoch',
  //   },
  //   'do': {
  //     name: 'Donnerstag',
  //   },
  //   'fr':{
  //     name: 'Freitag',
  //   },
  // }


  public static USER_COLLECTION = 'users'
  public static GEOHASH_PRECICION = 3

  public static defaultTax: Tax = {
    name: 'Umsatzssteuer 7%',
    value: 0.07,
    type: 'ERM'
  }

  public static customTax: Tax = {
    name: 'Eigener Steuersatz',
    type: 'CUSTOM'
  }
  public static taxPresets: Tax[] = [
    Constants.defaultTax,
    {
      name: 'Umsatzsteuer pauschalierend 10.7%',
      value: 0.107,
      type: 'PAU'
    },
    {
      name: 'Umsatzsteuer 19%',
      value: 0.19,
      type: 'NOR'
    },
    Constants.customTax
  ]

}


// export class Sidebars{
//   static "sidebar-user"
//   static "sidebar-shopping-cart"
//   static "sidebar-product-"
//   static "user-card" = true;
// }

@Injectable()
export class Globals {
  public redirectTo;
  public passRouting: any = {};
  public timestamp: number = new Date().getTime();
  public Constants = {
    routerLinkNews: {
      Kunde: "KUNDE",
      ORDER: "ORDER",
      FRIENDREQUEST: "FRIENDREQUEST",
      CHATMESSAGE: "CHATMESSAGE",
      SUGGESTION: "SUGGESTION"
    },
    FARMER: "FARMER",
    CUSTOMER: "CUSTOMER",
    order: {
      PRE_PENDING: "PRE_PENDING",
      PENDING: "PENDING",
      ACCEPTED: "ACCEPTED",
      DELIVERED: "DELIVERED",
      PAYED: "PAYED",
      DELIVER: "DELIVER",
      PICKUP: "PICKUP",
      CASH: "CASH",
      STRIPE: "STRIPE"
    },
    friendship: {
      ACCEPTED: "ACCEPTED",
      PENDING: "PENDING"
    },
    notifications: {
      NEW_REQUEST: "FRIENDSHIP",
      ACCEPTED: "FRIENDSHIP",
      CUSTOMER_CREATED: "ORDER",
      FARMER_ACCEPTED: "ORDER",
      CHAT: "CHAT"
    }
  }

  constructor(private toastService: ToastService) { }

  public isMobile = window.innerWidth <= 768;
  public get isLandwirt() {
    if (!this.user) {
      console.log('isLandwirtNoUser')
      return false
    }
    return this.user.typ == "FARMER" || this.user.typ == "SELLER" 
  }

  public get isFarmer(): Promise<boolean> {
    return this.getIsFarmer()
  }

  getIsFarmer = (): Promise<boolean> => new Promise((resolve, reject) => {
    if (!this.user) {
      setTimeout(() => this.getIsFarmer().then(resolve).catch(reject), 500);
    } else {
      resolve(this.user.typ == 'FARMER' || this.user.typ == "SELLER" )
    }
  })


  //ORDER
  public currentOrderUser;


  //THEME
  public theme = {
    showBack: true,
    showLogout: false,
    routeBack: null,
    showMenuButton: true,
    hideMenu: false,
    sidebar: {
      sidebarUser: false,
      sidebarShoppingCart: false,
      sidebarProduct: false,
      unitPrice: false,
      productOverview: false,
      showMenu: false,
      showShoppingCart: false,
      // showMenu: !this.isMobile
    }
  }



  //COMPONENT GLOBALS
  public product = {
    currentCategory: null,
    currentProductClass: null,
    currentProduct: null,
    currentPricelist: null,
  };


  public component = {
    chat: {
      subscription: null,
      conversations: null
    },
    invited: {
      showInvitedMessage: false
    }
  }

  // public sidebar:SidebarComponents[] = [];

  public setTheme(showMenuButton = true, showBack = true, routeBack = null) {
    // this.theme.showMenuButton = showMenuButton
    // this.theme.showBack = showBack
    // this.theme.routeBack = routeBack
    // this.theme.sidebar = {
    //   sidebarUser: true,
    //   sidebarShoppingCart: false,
    //   sidebarProduct: false,
    //   unitPrice: false,
    //   productOverview: false,
    //   showMenu: !this.isMobile
    // }
    // console.log(this.theme)
    // this.theme.showSidebarType = sidebarType
  }

  // public token;

  public user: User;
  public userObservable = new ReplaySubject<User>(1);
  public notAuthenticated;
  public get anonymousUser() {
    return firebase.auth().currentUser?.isAnonymous ? true : false
  }


  //GRAPH
  public graphUser;
  // public graphSockets:mainClasses = {
  //   conversations: null,
  //   pricelists: null,
  //   product_Users: null,
  //   users: null,
  //   orders: null,
  //   shopping_cart_news: null,
  //   news: null,

  //   categories: null,
  //   productclasses: null,
  //   products: null
  // }

  public graphData: mainClasses = {
    conversations: null,
    pricelists: null,
    product_Users: null,
    users: null,
    orders: null,
    shopping_cart_news: null,
    news: null,
    unitprices: null,
    friendships: null,
    friendshipsByUserId: null,

    categories: null,
    productclasses: null,
    products: null,
    productclassesByCategory: {},
    productsByProductclass: {}
  }

  public graphBehaviorSubject = <BehaviorSubject<any>>new BehaviorSubject({})
  public graphObserver = this.graphBehaviorSubject;
  public get graphObservable() {
    return this.graphBehaviorSubject.asObservable();
  }

  //   public graphObserver;
  //   public graphObservable = new Observable((observer) => {
  //     this.graphObserver = observer
  // })


  imageErrorHandler(event) {
    // console.debug(event);
    event.target.src = '/assets/images/icons/grey/Kunde_grau.png';
  }

  //ASSETS
  public URL = {
    files: 'https://files.beefeed.de',
    homepage: 'https://www.beefeed.de'
  }

  public lottieLogo = {
    path: 'assets/animation/logo/a.json',
    autoplay: true,
    loop: true
  };

  public lottieLogoStyles = {
    margin: '0 auto'
  }

  public defaultUnits = {
    "KG": "Kilogramm",
    "G": "Gramm",
    "L": "Liter",
    "STK": "Stück",
    "Bd": "Bund"
  }


  public imagesOrderStatus = {
    "PENDING": "EinkaufBestaetigt_grau",
    "ACCEPTED": "Bestaetigt_grau",
    "DELIVERED": "LKW_grau",
    "PAYED": "Bezahlt_grau"
  }

  public formatDate(date, short = false) {
    var monthNames = [
      "Januar", "Februar", "März",
      "April", "Mai", "Juni", "Juli",
      "August", "September", "Oktober",
      "November", "Dezember"
    ];
    if (short) {
      monthNames = [
        "Jan.", "Feb.", "Mär.",
        "Apr.", "Mai.", "Jun.", "Jul.",
        "Aug.", "Sep.", "Okt.",
        "Nov.", "Dez."
      ];
    }
    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return `${date.getDate()}. ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  }

  public formatDateTime(date) {
    var monthNames = [
      "Januar", "Februar", "März",
      "April", "Mai", "Juni", "Juli",
      "August", "September", "Oktober",
      "November", "Dezember"
    ];

    if (!(date instanceof Date)) {
      date = new Date(date)
    }

    return `${date.getDate()}. ${monthNames[date.getMonth()]}. ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }

  public mapToArray = (o) => o ? Object.keys(o).map((x: any) => Object.keys(o[x])?.length > 0 ? { ...o[x], id: x } : null).filter(x => x) : []



  public captureException(exception) {
    Sentry.captureException(exception);
  }

  public captureEvent(exception) {
    Sentry.captureEvent(exception);
  }


  public anonImg(image) {
    // console.log(image)
    image.src = '/assets/images/user/register/customer_icon.png';
  }

  public uuid() {
    return uuid();
  }

  public getKey() {
    return uuid();
  }

  public toJSON(obj) {
    return JSON.stringify(obj);
  }

  public HTTPerror(httpAnswer: any) {
    console.log(httpAnswer);
  }


  public HTTPhandleError<T>(operation = 'operation', result?: T,) {
    return (error: any): Observable<T> => {

      if (error.status == 401) {
        this.toastService.error({ title: "Entschuldigung!", message: "Du konntest leider nicht authentifiziert werden." })

        window.location.href = 'https://app.beefeed.de/login'
      } else if (error.status == 422) {
        if (error.error.errors) {
          console.log(error.error.errors)
          let title = (error.error.errors.length == 1) ? 'Es ist ein Fehler aufgetreten.' : 'Es sind mehrere Fehler aufgetreten.';
          var body = '';
          for (let k in error.error.errors) {
            body += "\n " + error.error.errors[k][0]
          }

          this.toastService.error({ title, message: body });
        }
      } else {
        this.toastService.error({ title: "Oh nein!", message: 'Das hätte nicht passieren dürfen. Bitte versuche die Seite neu zu laden.' });
      }
      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }





}
