import * as uuid from "../../../node_modules/uuid";
import { Constants } from "../Globals";
import deleteUndefined from "../_shared/functions/deleteUndefined";
import firebase from "firebase/app";
import "firebase/firestore";
export class ProductParent {
  id?: string = uuid.v4();

  path?: any[];
  show?: boolean;
  seasons?: Season[];

  constructor(o = null) {
    if (o) {
      Object.assign(this, o);
    }
  }

  toFirebase() {
    const r = { ...this, path: null };
    delete r.id;
    delete r.path;

    const s = deleteUndefined(JSON.parse(JSON.stringify(r)));
    if (this.seasons) {
      s.seasons = [];
      for (const i in this.seasons) {
        s.seasons[i] = this.seasons[i].toFirebase();
      }
    }
    return s;
  }

  toPath() {
    const r = { ...this, path: null };
    delete r.id;
    delete r.path;
    return r;
  }
}

export class Product extends ProductParent {
  name: string;
  //text wird aktuell bei beefeed verwendet @Tim @Nico
  productText?: string;
  //!BEEFEED TRANSFORMATION
  text?:string
  //!BEEFEED TRANSFORMATION ENDE
  imageProduct?: string;
  imageProducts?: string[];
  defaultUnit = "KG";
  unitprices: { [key: string]: Unitprice } = {};

  //    variants?: VariantContainer = new VariantContainer() <-- nur bei jumpspot

  tax: { [key: string]: Tax } = { default: Constants.defaultTax };
  visibleOnly?: boolean;
  //category?: string <-- nur bei jumpspot
  //publicationStatus: 'ACCEPTED' | 'PENDING' | 'DECLINED' = 'PENDING' <-- nur bei jumpspot

  constructor(o = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
      if (o.seasons) {
        this.seasons = [];
        for (const i in o.seasons) {
          this.seasons.push(new Season(o.seasons[i]));
        }
      }
    }
  }

  toStrucureFirebase = () =>
    JSON.parse(
      JSON.stringify({ name: this.name, imageProduct: this.imageProduct, imageProducts: this.imageProducts })
    );
}

export class Productclass extends ProductParent {
  name: string;
  imageProduct?: string;
  products?: Product[];
  productclasses?: Productclass[];

  constructor(o = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}

export class Category extends ProductParent {
  id?: string = uuid.v4();
  name: string;
  sort?: number;
  products?: Product[];
  productclasses?: Productclass[];

  constructor(o = null) {
    super(o);
    if (o) {
      Object.assign(this, o);
    }
  }
}

export class Unitprice {
  id?: string;
  unit_id: string = "";
  amount?: number;
  price?: number;
  isCalc = false;
  calc_unit_id: string = "";
  calc_amount?: number;
  calc_price?: number;
  shopping_cart_amount?: number;
  // isNotBinding?: boolean
  basicPrice?: string;
  pricelistId?: string;
  loading?: boolean;

  // productId?: string

  disabled = false;

  constructor(o = null) {
    if (o) {
      Object.assign(this, o);
    }
    console.log("NU", this);
  }

  toFirebase() {
    const r = JSON.parse(JSON.stringify(this));
    delete r.id;
    delete r.pricelistId;
    return r;
  }
}

export class Unit {
  id: string;
  name: string;
  short: string;
  defaultUnitRange: number = 0.0;
  range: [] = [];
}

export class GeneralHelper {
  name?: string;
  type?: string;
  value?: any;
}

export class Tax extends GeneralHelper {
  value?: number;
}

export class Season {
  start?: Date;
  end?: Date;
  activated: boolean;

  constructor(o = null) {
    if (o) {
      Object.assign(this, o);
      console.log(o);
      if (o.start && !(o.start instanceof Date) && o.start.toDate) {
        this.start = (o.start as firebase.firestore.Timestamp).toDate();
        // if (this.start.toString() == 'Invalid Date') {
        //     delete this.start
        // }
      }
      if (o.end && !(o.end instanceof Date) && o.end.toDate) {
        this.end = (o.end as firebase.firestore.Timestamp).toDate();
        console.log("END", this.end);
        // if (this.end.toString() == 'Invalid Date') {
        //     delete this.end
        // }
      }
    }
  }

  toFirebase() {
    const r = deleteUndefined(JSON.parse(JSON.stringify(this)));
    if (this.end) {
      r.end = firebase.firestore.Timestamp.fromDate(this.end);
    }
    if (this.start) {
      r.start = firebase.firestore.Timestamp.fromDate(this.start);
    }
    delete r.id;
    return r;
  }
}
