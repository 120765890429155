import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { Globals } from './Globals';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { ToastService } from './ui-components/toast.service';
import { SharedService } from './shared.service';
import { SwUpdate } from '@angular/service-worker';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public isIOS
  //test
  title = 'app';

  public loading = true
  public temp = true
  public hideMenu = false
  private wasOffline = false
  public logoutLink?: SafeResourceUrl

  constructor(
    private router: Router,
    private Globals: Globals,
    private route: ActivatedRoute,
    public authService: AuthService,
    private toastService: ToastService,
    private sharedService: SharedService,
    public updates: SwUpdate,
    private sanitizer: DomSanitizer
  ) {
    this.router.events.subscribe((event: RouterEvent) => this.navigationInterceptor(event))
    this.isIOS = this.iOS()
    this.sharedService.isIOS = this.isIOS
    this.logoutLink = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.host === 'localhost:4200' ? 'https://localhost:4201?logout=1' : 'https://dashboard.beefeed.de?logout=1')


    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => this.updateApp());
    });
  }

  updateApp() {
    // document.location.reload();
    window.location.href = window.location.href
    console.log("The app is updating right now");
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.hideMenu == 1) {
        this.hideMenu = true
        setTimeout(() => this.Globals.theme.hideMenu = true, 100);
      }
    });

    // console.log('BUILD', this.envTimeStamp, environment.production)
    this.authService.initAuth()


    window.addEventListener('online', async (e) => {
      console.log('online')
      if (this.wasOffline) {
        this.toastService.success({ title: 'Internetverbindung wiederhergestellt 😍' })
      }
      this.wasOffline = false;
    });
    window.addEventListener('offline', async (e) => {
      console.log('offline')
      this.toastService.error({ title: 'Oh nein! Keine Internetverbindung gefunden.', message: 'Bitte verbinde dich wieder mit dem Internet, sonst können wir nicht weitermachen...' })
      this.wasOffline = true;
    });

    if (!environment.production) {
      document.documentElement.style.setProperty('--background-color', '#c8c8c8');
    }
  }

  openInApp() {
    const child = this.route.snapshot.firstChild
    console.log('open', this.route.snapshot)
    let path
    const url = window.location.pathname?.split('/')
    console.log(url)
    switch (child.routeConfig.path) {
      case 'order':
        if (url?.length > 4) {
          path = `type=order&id=${url[4]}&farmerId=${url[3]}`
        }
        break;
      case 'user':
        if (url.length > 2) {
          path = `type=user&id=${url[2]}`
        }
        break;
    }
    console.log(path)
    window.location.href = `https://use.beefeed.de/?link=https://app.beefeed.de/?${encodeURIComponent(path) ?? 'e'}&isi=1450206677&ibi=moma-gmbh.beefeed&efr=1`
  }

  private timeout
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.timeout = setTimeout(() => this.loading = this.temp = true, 10);
    }
    if (event instanceof NavigationEnd) {
      clearTimeout(this.timeout)
      setTimeout(() => this.loading = this.temp = false);
    }

    if (event instanceof NavigationCancel) {
      setTimeout(() => this.loading = this.temp = false);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => this.loading = this.temp = false);
    }
    this.temp = false
  }
}
