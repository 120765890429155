<nav class="navbar navbar-light sticky" *ngIf="!Globals.theme.hideMenu">
  <a class="navbar-brand">
    <div *ngIf="(Globals.theme.showBack || backLink) && !hideBack" (click)="back()">
      <i class="fas fa-chevron-left"></i>
    </div>
    <ng-container *ngIf="Globals.user">
      <div *ngIf="Globals.user && Globals.theme.showLogout && !Globals.theme.showBack && !hideBack" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i>
      </div>
    </ng-container>
  </a>
  <div class="navbar-brand" *ngIf="showLogo">
    <img class="logo" width="80px" src="https://files.beefeed.de/assets/logo/logo_150_weiss.png" alt=""
      (error)="Globals.imageErrorHandler($event)">
    <!-- <img class="logo" width="80px"
      src="https://files.beefeed.de/assets/logo/logo_150_schwarz.png" alt=""
      (error)="Globals.imageErrorHandler($event)"> -->
  </div>
  <a class="navbar-brand menu">
    <ng-container *ngIf="Globals.user">
      <div *ngIf="!hideMenu && MessagingService.showIcon && !orderService.shoppingCartCount"
        (click)="MessagingService.getPermission()">
        <i class="fas fa-bell"></i>
      </div>
      <div class="cart" [class.cartAnimation]="orderService.pulseAnimation"
        *ngIf="!isFarmer && !hideMenu && !MessagingService.showIcon || orderService.shoppingCartCount" (click)="cart()">
        <i class="fas fa-shopping-cart"></i>
        <p class="cartNr">{{orderService.shoppingCartCount ?orderService.shoppingCartCount:0}}</p>
      </div>
      <div *ngIf="Globals.theme.showMenuButton && !hideMenu" (click)="home()">
        <i class="fas fa-home"></i>
      </div>
      <div *ngIf="Globals.theme.showMenuButton && !hideMenu" (click)="menu()">
        <i class="fas fa-bars"></i>
      </div>
    </ng-container>
  </a>
</nav>
