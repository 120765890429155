import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Globals } from './Globals';
import { ProductService } from './product/product.service';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, COLLECTION_ENABLED } from '@angular/fire/analytics';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireFunctionsModule, ORIGIN, REGION } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { ToastService } from './ui-components/toast.service';
import { MainCookieService } from './auth/cookie.service';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from "@sentry/angular";
import { MessagingService } from './messaging.service';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { HttpClientModule } from '@angular/common/http';


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFirePerformanceModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    Ng2IziToastModule,
    HttpClientModule,
    UiComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    { provide: REGION, useValue: 'europe-west3' },
    { provide: ORIGIN, useValue: (environment.production || !environment.firebase.useLocal) ? undefined : 'http://localhost:5002' },
    {
      provide: SETTINGS,
      useValue: (environment.production || !environment.firebase.useLocal) ? undefined : {
        host: 'localhost:8080',
        ssl: false
      }
    },
    { provide: COLLECTION_ENABLED, useValue: false },
    Globals,
    ProductService,
    CookieService,
    ToastService,
    MainCookieService,
    MessagingService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
