import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Constants } from '../Globals';
import { Category, Product, Productclass, Tax } from '../models/product.model';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ToastService } from '../ui-components/toast.service';
import { SharedService } from '../shared.service';
import deleteUndefined from '../_shared/functions/deleteUndefined';
import FlexSearch from 'flexsearch';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { KeyValue } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  //TODO restliche .subscibe
  public farmer = {
    structure: null,
    path: [],
    newProductclass: null,
    newCategory: null,
  }

  public searchIndex
  public suggestions
  public loading = false

  private subscriptionByFunction = {
    get: {},
    loadProductStructure: null,
  }
  private firebaseSubscriptions = []

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private sharedService: SharedService,
  ) {
    this.authService.unsubscribeAll.subscribe(() => {
      this.sharedService.unsubscribeAll(this.subscriptionByFunction)
      this.sharedService.unsubscribeAll(this.firebaseSubscriptions)
      console.log('productService/unsubscribedAll')
    })
  }

  sortByName = (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value?.name < b.value?.name ? -1 : (b.value?.name < a.value?.name ? 1 : 0);

  get(productId: string): Observable<Product> {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc<Product>(productId).valueChanges().pipe(map(x => new Product({ ...x, id: productId })))

    console.log('productService/get', productId)
    if (!this.subscriptionByFunction.get[productId]) {
      console.log('productService/get/callFirebase', productId)
      this.subscriptionByFunction.get[productId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc<Product>(productId).valueChanges()
        .pipe(map(x => new Product({ ...x, id: productId })))
        .subscribe(res => {
          this.subscriptionByFunction.get[productId].next(res)
        }))
    }
    return this.subscriptionByFunction.get[productId]
  }

  setPathByProduct(productId) {
    console.log('runSetPath')
    if (!this.farmer.structure) {
      console.error('productService/NO_STRUCTURE')
      return
    }

    const analyzeResult = this.pathAnalyzeCategories(this.farmer.structure, 'category', productId)
    if (analyzeResult) {
      this.farmer.path = analyzeResult
    } else {
      console.error('productService/PATH_NOT_FOUND', this.farmer.path)
      return
    }

    console.log('productService/path', this.farmer.path)
    return this.farmer.path
  }

  private pathAnalyzeCategories(cs: Category, typ: string, productId: string) {
    if (cs) {
      for (let k of Object.keys(cs)) {
        let analyzeResult
        if (cs[k].products) {
          analyzeResult = this.pathAnalyzeProducts(cs[k].products, productId)
        }
        if (cs[k].productclasses) {
          analyzeResult = this.pathAnalyzeCategories(cs[k].productclasses, 'productclass', productId)
        }
        if (cs[k].subcategories) {
          analyzeResult = this.pathAnalyzeCategories(cs[k].subcategories, 'category', productId)
        }
        if (analyzeResult) {
          return [{ ...cs[k], typ, id: k }, ...analyzeResult]
        }
      }
    }
  }


  private pathAnalyzeProducts(ps, productId: string) {
    for (let k of Object.keys(ps)) {
      if (k == productId) {
        return [{ ...ps[k], typ: 'product', id: k }]
      }
    }
  }


  isProductsSetup = () => new Promise<boolean>(resolve =>
    this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products', ref => ref.limit(1)).get().subscribe(res => resolve(res.size == 1)))

  myProducts = () => {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').valueChanges({ idField: 'id' }).pipe(map(x => x.map(o => new Product(o))))
  }

  loadProductStructure = () => {
    console.log(this.farmer.structure)
    if (!this.subscriptionByFunction.loadProductStructure) {
      this.subscriptionByFunction.loadProductStructure = new ReplaySubject(1)
      this.loading = true
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc<{ [key: string]: Category }>('categories').valueChanges().subscribe(res => {
        this.sharedService.buildPathCategories(res, 'category')
        this.farmer.structure = res
        this.loading = false
        console.log('REd', res)
        // @ts-ignore
        this.searchIndex = new FlexSearch({
          encode: "icase",
          tokenize: "full",
          doc: {
            id: "id",
            field: "name"
          }
        })
        this.searchIndex.add(this.sharedService.getProductsListed(res))
        this.subscriptionByFunction.loadProductStructure.next(res)
      }))
    }
    return this.subscriptionByFunction.loadProductStructure
  }

  loadSuggestions = () => {
    this.firebaseSubscriptions.push(this.firestore.collection('static').doc<{ [key: string]: any }>('appMap').valueChanges().subscribe(res => this.suggestions = res))
  }


  update(id: string, product: Product | any) {
    if (!this.farmer.path && product instanceof Product) {
      console.log('NO PATH')
      return Promise.resolve()
    }
    console.log(product)
    const path = this.farmer.path ? (this.pathToFirebaseKey(this.farmer.path) + '.products.' + id) : null

    let batch = this.firestore.firestore.batch();

    if (product instanceof Product) {
      if (path) {
        const toStruct = product.toStrucureFirebase()
        console.log(path, id, product.toStrucureFirebase())
        const cRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories')
        batch.update(cRef, { [path]: toStruct });
      }
      const toFirebase = product.toFirebase()
      console.log('------tofirebase1', toFirebase)
      const dRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id)
      batch.set(dRef, toFirebase);
    } else {
      const toFirebase = new Product(product).toFirebase()
      if (Object.keys(toFirebase).filter(x => x.indexOf('unitprices') !== -1).length > 1) {
        delete toFirebase.unitprices
      }
      console.log('------tofirebase2', toFirebase)
      if (path && (product.name || product.imageProduct || product.imageProducts)) {
        const cRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories')
        batch.update(cRef, { [path]: deleteUndefined({ name: product.name, imageProduct: product.imageProduct, imageProducts: product.imageProducts }) });
      }
      const dRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id)
      batch.update(dRef, toFirebase);
    }

    return batch.commit()


    // if (product instanceof Product) {
    //   return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id).set(product.toFirebase(), { merge: true })
    // }
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id).update(product)
  }

  delete(id: string, product: Product | any) {
    if (!this.farmer.path && product instanceof Product) {
      console.log('NO PATH')
      return Promise.resolve()
    }
    console.log(product)
    const path = this.farmer.path ? (this.pathToFirebaseKey(this.farmer.path) + '.products.' + id) : null

    let batch = this.firestore.firestore.batch();
    batch.delete(this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id))

    if (path) {
      const cRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories')
      batch.update(cRef, { [path]: firebase.firestore.FieldValue.delete() });
    }

    return batch.commit()
  }

  deleteProductclass(product: any) {
    if (!product.path) {
      console.log('NO PATH')
      return Promise.resolve()
    }
    const path = this.farmer.path ? (this.pathToFirebaseKey(product.path) + '.productclasses.' + product.id) : null
    let batch = this.firestore.firestore.batch();

    if (path) {
      const cRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories')
      batch.update(cRef, { [path]: firebase.firestore.FieldValue.delete() });
    }

    return batch.commit()
  }

  deleteCategory(id: string) {
    let batch = this.firestore.firestore.batch();

    const cRef = this.firestore.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories')
    batch.update(cRef, { [id]: firebase.firestore.FieldValue.delete() });

    return batch.commit()


    // if (product instanceof Product) {
    //   return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id).set(product.toFirebase(), { merge: true })
    // }
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('products').doc(id).update(product)
  }

  //Productclass
  updateProductclass(id: string, productclass: Productclass) {
    console.log(productclass)
    if (!productclass.path) {
      console.log('NO PATH')
      //TODO @Tim Pfad holen
      return Promise.resolve()
    }

    if(!productclass.name){
      this.toastService.error({ title: 'Es muss ein Name vergeben werden' })
      return
    }

    if (!productclass.imageProduct) {
      this.toastService.error({ title: 'Es muss ein Bild ausgewählt werden' })
      return
    }
    const path = this.pathToFirebaseKey(productclass.path) + '.productclasses.' + id
    this.toastService.closeModal('addProductclass')
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories').update({ [path]: productclass.toFirebase() }).catch(err => console.log('updatePricelistError', err))
  }

  updateCategory(id: string, category: Category) {
    console.log(category)
    if(!category.name){
      this.toastService.error({ title: 'Es muss ein Name vergeben werden' })
      return
    }
    this.toastService.closeModal('addCategory')
    if (!this.farmer.structure) {
      return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories').set({ [id]: category.toFirebase() }, { merge: true }).catch(err => console.log('updateCategoryError', err))
    }
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('categories').update({ [id]: category.toFirebase() }).catch(err => console.log('updateCategoryError', err))
  }

  pathToFirebaseKey(path) {
    if (!path || path.length == "") {
      return ""
    }

    console.log('path', path)
    let res = path[0].id
    for (let i = 1; i < path.length; ++i) {
      const typ = (path[i].typ == 'category') ? 'categories' : (path[i].typ == 'productclass') ? 'productclasses' : null
      if (typ == null) {
        return res
      }
      res += `.${typ}.${path[i].id}`
    }
    console.log('po', res)
    return res
  }





  //FARMER

  selectPathWithPath(path) {
    this.farmer.path = path
    this.handlePathNavigation()
  }

  selectPath(typ, level, id, body) {
    if (!(this.farmer.path instanceof Array)) {
      this.farmer.path = []
    }
    console.log(level, JSON.stringify(this.farmer.path.length))
    if (level < this.farmer.path.length) {
      console.log('bigger')
      this.farmer.path.splice(level, this.farmer.path.length - level)
    }
    this.farmer.path.push({ ...body, typ: typ, id: id })
    console.log('path', this.farmer.path)
    this.handlePathNavigation()
  }

  backPath(steps = 1) {
    this.farmer.path.pop()
    this.handlePathNavigation()
  }

  private handlePathNavigation() {
    if (this.farmer.path[this.farmer.path.length - 1]?.typ == 'product') {
      this.router.navigate([`/product/farmer/${this.farmer.path[this.farmer.path.length - 1].id}`])
    } else {
      this.router.navigate(['/product/farmer'])
    }
  }
}
