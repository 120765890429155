import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
// var cookiee = require('cookie-encryption');
 
@Injectable({
  providedIn: 'root'
})
export class MainCookieService {
  private cookies = {};
  constructor(public CookieService:CookieService) {}


  public set(name, value){
    this.CookieService.delete(name)
    // if((typeof value) != "string" ){
      value = JSON.stringify(value);
    // }
    value = btoa(value);
    this.cookies[name] = value;
    return this.CookieService.set(name, value)
  }

  public check(name){
    // console.log(this.cookies[name], this.CookieService.check(name))
    if(this.cookies[name]){
      return true;
    }
    return this.CookieService.check(name)
  }

  public get(name):any{
    var cookie = this.cookies[name] || this.CookieService.get(name);
    if(cookie){
      try{
        var result = atob(cookie);
        result = JSON.parse(result);
      }catch(e){
        console.log('COOKIE PARSE ERROR' , e)
        // this.CookieService.delete(name)
        // window.location.reload()
      }
      return result || null;
    }
    return null;
  }

  public delete(name){
    this.cookies[name] = null
    this.CookieService.delete(name, "/", environment.cookieURL)
  }

  public clear(){
    this.cookies = {};
    this.CookieService.deleteAll("/", environment.cookieURL);
  }

}
