import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

export class ToastTypes {
  static show: string = "show"
  static info: string = "info"
  static success: string = "success"
  static error: string = "error"
  static warning: string = "warning"
}

@Injectable({
  providedIn: 'root'
})


export class ToastService {

  private modals: any[] = [];


  private parentOptions = {
    id: '',
    class: 'toastCustom',
    title: '',
    titleColor: '#FFFFFF',
    titleSize: '',
    titleLineHeight: '',
    message: '',
    messageColor: '#FFFFFF',
    messageSize: '',
    messageLineHeight: '',
    backgroundColor: '#FFFFFF',
    theme: 'dark', // dark
    color: 'green', // blue, red, green, yellow
    icon: 'warning',
    iconText: '',
    iconColor: '#FFFFFF',
    iconUrl: null,
    layout: 1,
    balloon: false,
    close: true,
    closeOnEscape: true,
    closeOnClick: false,
    displayMode: 0, // once, replace
    position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
    target: '',
    targetFirst: true,
    timeout: 5000,
    rtl: false,
    animateInside: true,
    drag: true,
    pauseOnHover: true,
    resetOnHover: false,
    progressBar: false,
    progressBarColor: '',
    progressBarEasing: 'linear',
    overlay: false,
    overlayClose: false,
    overlayColor: 'rgba(0, 0, 0, 0)',
    transitionIn: 'fadeInDown',
    transitionOut: 'fadeOutUp',
    transitionInMobile: 'fadeInDown',
    transitionOutMobile: 'fadeOutUp',
    buttons: {},
    inputs: {},
    onOpening: function () { },
    onOpened: function () { },
    onClosing: function () { },
    onClosed: function () { }
  }




  constructor(public iziToast: Ng2IzitoastService) { }

  show(customOptions, func = ToastTypes.info) {
    var options = {
      id: '',
      class: 'toastCustom',
      title: '',
      titleColor: '#FFFFFF',
      titleSize: '',
      titleLineHeight: '',
      message: '',
      messageColor: '#FFFFFF',
      messageSize: '',
      messageLineHeight: '',
      backgroundColor: '#2ecc71',
      theme: 'dark', // dark
      color: 'white', // blue, red, green, yellow
      icon: 'emoji-grinning',
      iconText: '',
      iconColor: '#FFFFFF',
      iconUrl: null,
      layout: 1,
      balloon: false,
      close: true,
      closeOnEscape: true,
      closeOnClick: false,
      displayMode: 0, // once, replace
      position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
      target: '',
      targetFirst: true,
      timeout: 5000,
      rtl: false,
      animateInside: true,
      drag: true,
      pauseOnHover: true,
      resetOnHover: false,
      progressBar: false,
      progressBarColor: '',
      progressBarEasing: 'linear',
      overlay: false,
      overlayClose: false,
      overlayColor: 'rgba(0, 0, 0, 0)',
      transitionIn: 'fadeInDown',
      transitionOut: 'fadeOutUp',
      transitionInMobile: 'fadeInDown',
      transitionOutMobile: 'fadeOutUp',
      buttons: {},
      inputs: {},
      onOpening: function () { },
      onOpened: function () { },
      onClosing: function () { },
      onClosed: function () { }
    }

    // var options = this.parentOptions;
    if (customOptions) {
      for (var opt in customOptions) {
        // console.log(opt, customOptions[opt])
        options[opt] = customOptions[opt];
      }
    }
    // console.log(options)

    if (func && options)
      this.iziToast[func](options);
  }


  success(customOptions) {
    customOptions.backgroundColor = '#2ecc71'
    customOptions.color = 'green'
    customOptions.icon = 'emoji-grinning'
    this.show(customOptions, ToastTypes.success)
  }

  info(customOptions) {
    customOptions.backgroundColor = '#f1c40f'
    customOptions.color = 'yellow'
    customOptions.icon = 'emoji-grinning'
    this.show(customOptions, ToastTypes.info)
  }


  error(customOptions) {
    customOptions.backgroundColor = '#e74c3c'
    customOptions.color = 'red'
    customOptions.icon = 'emoji-flushed'
    this.show(customOptions, ToastTypes.error)
  }

  warning(customOptions) {
    customOptions.backgroundColor = '#e67e22'
    customOptions.color = 'orange'
    customOptions.icon = 'emoji-point-up'
    this.show(customOptions, ToastTypes.warning)
  }


  hide(toast) {
    this.iziToast.hide(toast);
  }



  addModal(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  removeModal(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  openModal(id: string) {
    // open modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  closeModal(id: string) {
    // close modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }




}
