import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiNewsComponent } from './ui-news/ui-news.component';
import { UiSidebarUserComponent } from './ui-sidebar-user/ui-sidebar-user.component';
import { UiUserCardComponent } from './ui-user-card/ui-user-card.component';
import { UiDashboardOrdersLastComponent } from './ui-dashboard-orders-last/ui-dashboard-orders-last.component';
import { UiCardProductComponent } from './ui-card-product/ui-card-product.component';
import { UiCardNewsComponent } from './ui-card-news/ui-card-news.component';
import { UiButtonComponent } from './ui-button/ui-button.component';
import { UiSidebarShoppingCartComponent } from './ui-sidebar-shopping-cart/ui-sidebar-shopping-cart.component';
import { UiSidebarDynamicComponent } from './ui-sidebar-dynamic/ui-sidebar-dynamic.component';
import { UiSidebarUnitPriceComponent } from './ui-sidebar-unit-price/ui-sidebar-unit-price.component';
import { UiSidebarProductFarmerOverviewComponent } from './ui-sidebar-product-farmer-overview/ui-sidebar-product-farmer-overview.component';
import { UiOrderListComponent } from './ui-order-list/ui-order-list.component';
import { UiMenuItemComponent } from './ui-menu-item/ui-menu-item.component';
import { UiMenuComponent } from './ui-menu/ui-menu.component';
import { ModalComponent } from './modal/modal.component';
import { UiProductFarmerBreadcrumbComponent } from './ui-product-farmer-breadcrumb/ui-product-farmer-breadcrumb.component';
import { UiCardProductProductclassComponent } from './ui-card-product-productclass/ui-card-product-productclass.component';
import { RouterModule } from '@angular/router';
import { UiUserCardMiniComponent } from './ui-user-card-mini/ui-user-card-mini.component';
import { UiShoppingcartComponent } from './ui-shoppingcart/ui-shoppingcart.component';
import { UiSidebarComponent } from './ui-sidebar/ui-sidebar.component';
import { UiHeaderComponent } from './ui-header/ui-header.component';
import { UiLoadingSmallComponent } from './ui-loading-small/ui-loading-small.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { SafePipe } from '../safe.pipe';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [ModalComponent, UiHeaderComponent, UiSidebarComponent, UiNewsComponent, UiSidebarUserComponent, UiUserCardComponent, UiDashboardOrdersLastComponent, UiCardProductComponent, UiCardNewsComponent, UiButtonComponent, UiSidebarShoppingCartComponent, UiSidebarDynamicComponent, UiSidebarUnitPriceComponent, UiSidebarProductFarmerOverviewComponent, UiOrderListComponent, UiMenuItemComponent, UiMenuComponent, UiProductFarmerBreadcrumbComponent, UiCardProductProductclassComponent, UiUserCardMiniComponent, UiShoppingcartComponent, UiLoadingSmallComponent, CookieConsentComponent, SafePipe],
  exports: [
    ModalComponent,
    UiHeaderComponent,
    UiSidebarComponent,
    UiNewsComponent,
    UiSidebarUserComponent,
    UiUserCardComponent,
    UiDashboardOrdersLastComponent,
    UiCardNewsComponent,
    UiCardProductComponent,
    UiButtonComponent,
    UiSidebarShoppingCartComponent,
    UiSidebarUnitPriceComponent,
    UiSidebarProductFarmerOverviewComponent,
    UiOrderListComponent,
    UiMenuComponent,
    UiProductFarmerBreadcrumbComponent,
    UiCardProductProductclassComponent,
    UiUserCardMiniComponent,
    UiShoppingcartComponent,
    UiLoadingSmallComponent,
    CookieConsentComponent,
    SafePipe,
  ],
})
export class UiComponentsModule { }
