import firebase from 'firebase/app';
import 'firebase/firestore';
import deleteUndefined from '../_shared/functions/deleteUndefined';
import { Pricelist } from './order.model';

export class User {
    id?: string
    name?: string   //firstName?: string  + lastName?: string bei jumpspot 
    username?: string // -> wird zu company
    email?: string
    phoneNumber?: string
    visibleOnly?: boolean
    typ?: string
    disabled = false
    banUser = false
    address?: Address
    addresses?: Address[]
    agb?: boolean // <-- macht das Sinn? 
    orderInfo?: {
        acceptedDeliverType?: Array<string>

        // acceptedDeliverType?: { <-- sollte so auch funktionieren wir bei jumpspot
        //     pickup?: boolean
        //     deliver?: boolean
        // }


        // defaultPickupDiscountRel?: number
        // defaultPickupDiscountAbs?: number

        defaultMinDeliver?: number
        defaultDeliverRate?: number
        freeDeliveryAbove?: number
        paymentMethods?: Array<string>

        // paymentMethods?: { <-- sollte so auch funktionieren wir bei jumpspot
        //     stripe?: boolean
        //     paypal?: boolean
        //     cash?: boolean
        // }


        payLater?: boolean
        publicPricelistId?: string
       // paypalAcct?: string <-- bisher nur bei jumpspot

        stripeAcct?: string
        pickupOptions?: Array<string>
        deliverOptions?: Array<string>
        pickupHideDate?: boolean
        deliverHideDate?: boolean
        returnAddress?:string
    } = {}

    //featured?: any <-- so auch bei beefeed (also kommt irgendwann)
    stripeCustomer?: string
    profileImage?: string
    impressum?: Impressum
    website?: string
    //freeUntil?: Date <-- nur bei jumpspot 

    notifications: {
        email: boolean
    } = { email: true }
    about: {
        slug?: string,
        html?: string,
       // color: string, <-- nur bei jumpspot 

        slider?: string[],

        // slider?: {   <-- so auch bei beefeed
        //     title?: string,
        //     image?: string
        // }[],

        // tags?: string[], <-- nur bei jumpspot 

        showProducts: boolean,
        showAbout: boolean,
        showAboutBeefeed: boolean,

        // showAboutJumpspot: boolean, <-- einheitlichen Namen finden


    } = {
            showAbout: true,
            showAboutBeefeed: true,
            showProducts: true
        }

    constructor(o = null) {
        if (o) {
            Object.assign(this, o)
        }
    }

    toFirebase() {
        const r = JSON.parse(JSON.stringify(this))
        delete r.id
        r.updated_at = firebase.firestore.FieldValue.serverTimestamp()
        return deleteUndefined(r)
    }

    toFirebaseStripped = () => deleteUndefined({
        id: this.id,
        username: this.username,
        name: this.name,
        typ: this.typ,
        profileImage: this.profileImage,
    })

    toFirebaseStrippedShopping = () => deleteUndefined({
        id: this.id,
        username: this.username,
        name: this.name,
        typ: this.typ,
        profileImage: this.profileImage,
        orderInfo: this.orderInfo,
    })

    toFirebaseStrippedFriendship = () => deleteUndefined({
        id: this.id,
        username: this.username,
        name: this.name,
        typ: this.typ,
        profileImage: this.profileImage,
        orderInfo: this.orderInfo,
        visibleOnly: this.visibleOnly
    })

}


export class Address {
    country?: string
    street?: string
    city?: string
    zip?: string
    lat?: string
    long?: string
    typ? : string
    text? : string
}

export class Impressum {
    geschaeftsName = ""
    geschaeftsArt = ""
    handelsregisternummer = ""
    ustid = ""
    unternehmensvertreter = ""
    tel = ""
    adresse = ""
    cancellationPolicyText?: string
    agbLink?: string
    agbText?: string
}

export class Friendship {
    id?: string
    user?: User
    requestBy: string
    statusCode = "PENDING"
    pricelistId?: string
    sentNotification: string[] = []

    constructor(o: Partial<Friendship> = null) {
        if (o) {
            Object.assign(this, o)
        }
    }
}


// export class Coupon { <--- gibts noch net be beefeed
//     id?: string
//     title?: string
//     code?: string
//     freeShipping?: boolean
//     publishable?: boolean
//     discounttype?: string
//     amount?: string
//     status?: boolean = true
//     limitTotal?: number
//     limitLeft?: number

//     startDate: Date
//     endDate: Date

//     toFirebase() {
//         const r = deepClone(this)
//         delete r.id
//         return r
//     }


//     constructor(o = null) {
//         if (o) {
//             Object.assign(this, o)
//         }
//     }
// }