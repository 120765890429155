export const environment = {
  production: true,
  cookieURL: "beefeed.de",
  firebase: {
    useLocal: false,
    apiKey: "AIzaSyAgn5Pel_zj4hFCgLeTqzH7gYXtOTNV250",
    authDomain: "beefeed-app.firebaseapp.com",
    databaseURL: "https://beefeed-app.firebaseio.com",
    projectId: "beefeed-app",
    storageBucket: "beefeed-app.appspot.com",
    messagingSenderId: "947514179528",
    appId: "1:947514179528:web:ff72f98f4262429d03cafd",
    measurementId: "G-WH78K9JVGK"
  },
  stripe: {
    connectId: 'ca_Gx7Cik5F1AtwSDAGNG2ra0Duf3BDkFTj',
    publicKey: 'pk_live_dHZWlD5rQfsZXWee46kGBhCm00PZlOFkzt'
  },
  EKEY: 'tyK3AddUKzbUFgu5xOPOvymU8eptVrui'
};
