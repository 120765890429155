import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/Globals';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastService } from '../toast.service';
import { MessagingService } from 'src/app/messaging.service';
import { OrderService } from 'src/app/order/order.service';

@Component({
  selector: 'ui-header',
  templateUrl: './ui-header.component.html',
  styleUrls: ['./ui-header.component.css']
})
export class UiHeaderComponent implements OnInit, OnDestroy {
  // @Input() routeBack:any;
  // @Input() showBack:boolean = true;
  @Input() showLogo: boolean = true;
  @Input() hideMenu: boolean = false;
  @Input() hideBack: boolean = false;
  @Input() backLink: string;
  cartLoaded
  isFarmer
  subscription

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public Globals: Globals,
    private AuthService: AuthService,
    private ToastService: ToastService,
    public MessagingService: MessagingService,
    public orderService: OrderService,
  ) { }

  ngOnInit() {
    if (this.Globals.theme.routeBack) {
      this.Globals.theme.showBack = true;
    }
    this.Globals.isFarmer.then(isFarmer => {
      this.isFarmer = isFarmer
      if (!isFarmer) {
        console.log('startLoadCart')
        this.subscription = this.orderService.loadShoppingCart().subscribe(res => {
          console.log('loadShoppingCart')
          this.cartLoaded = true
        })
      }
    })
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }

  back() {
    if(this.backLink){
      this.router.navigateByUrl(this.backLink);
    }else    if (this.Globals.theme.routeBack) {
      console.log('routeBack')
      this.router.navigateByUrl(this.Globals.theme.routeBack);
    } else {
      this._location.back()
      // this.router.navigate(['..'], { relativeTo: this.route })
    }
    return false;
  }

  menu() {
    this.Globals.theme.sidebar.showShoppingCart = false
    this.Globals.theme.sidebar.showMenu = !this.Globals.theme.sidebar.showMenu
    // console.log('MENU', this.Globals.theme.sidebar.showMenu)
  }

  home() {
    this.Globals.theme.sidebar.showMenu = false
    this.Globals.theme.sidebar.showShoppingCart = false
    this.router.navigate(['/dashboard']);
  }

  cart() {
    this.Globals.theme.sidebar.showMenu = false
    // this.router.navigate(['/order/cart'])
    this.Globals.theme.sidebar.showShoppingCart = !this.Globals.theme.sidebar.showShoppingCart
    // console.log('MENU', this.Globals.theme.sidebar.showMenu)
  }

  logout() {
    this.ToastService.info({
      title: 'Willst du dich wirklich Ausloggen?',
      position: 'center',
      color: 'red',
      buttons: [
        ['<button>Ja</button>', (instance, toast) => {
          this.AuthService.logout();

          instance.hide({ transitionOut: 'fadeOutUp', }, toast, 'buttonName');
        }, true],
        ['<button>Nein</button>', function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOutUp', }, toast, 'buttonName');
        }]
      ]
    });
  }

}
