import { Injectable } from "@angular/core";
import { Constants, Globals } from "../Globals";
import { ToastService } from "../ui-components/toast.service";
import { Router } from "@angular/router";
import { first, take } from "rxjs/operators";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { User } from "../models/user.model";
import { AuthRegisterService } from "./auth-register/auth-register.service";
import { ReplaySubject, Subject } from "rxjs";
import * as Sentry from "@sentry/angular";
import { AngularFireFunctions } from "@angular/fire/functions";
declare let Tawk_API;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public accountRegisteredButNoUserData = false;
  public retry = true;
  public user: firebase.User;
  public userObservable = new ReplaySubject<firebase.User>(1);
  private subscription;
  public unsubscribeAll = new Subject();
  public ssoToken;

  constructor(
    private ToastService: ToastService,
    private router: Router,
    private Globals: Globals,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private registerService: AuthRegisterService,
    private firebaseFunctions: AngularFireFunctions
  ) { }

  get isAnonymous() {
    return this.user?.isAnonymous ? true : false;
  }

  async loginAnonymous(): Promise<boolean> {
    try {
      const r = await this.angularFireAuth.signInAnonymously();
      if (r) {
        this.user = r.user;
        return Promise.resolve(r ? true : false);
      }
    } catch (e) {
      return Promise.reject();
    }
  }

  initAuth() {
    this.angularFireAuth.onAuthStateChanged(async (user) => {
      // this.loading = false
      console.log("USER", user);
      this.user = user;
      this.userObservable.next(user);

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      if (user) {
        this.subscription = this.firestore
          .collection(Constants.USER_COLLECTION)
          .doc<any>(user.uid)
          .valueChanges()
          .subscribe((userData) => {
            this.Globals.user = new User(userData);
            this.Globals.userObservable.next(userData);
            console.log("userData", userData);
            if (userData?.typ === "FARMER" || userData?.typ === "SELLER") {
              console.log("token-seller");
              this.firebaseFunctions
                .httpsCallable("getSSOtoken")({})
                .subscribe((res) => {
                  console.log("gotToken", res);
                  if (res?.token) this.ssoToken = res.token;
                });
            }

            if (userData && userData.name) {
              if (Tawk_API && Tawk_API.setAttributes) {
                try {
                  Tawk_API.setAttributes({
                    name: userData.name + " - " + userData.username,
                    email: userData.email,
                    hash: user.uid,
                  });
                } catch (e) {
                  console.log("tawk error", e);
                }
              }
              if (Sentry?.setUser) {
                Sentry.setUser({ email: userData.email, id: user.uid });
              }
              console.log("newData", userData);
            } else if (
              !this.registerService.running &&
              !this.user?.isAnonymous &&
              !userData?.name
            ) {
              this.registerService.user = userData;
              // this.ToastService.info({ title: 'Bitte Registrierung abschließen' })
              this.router.navigate(["/register/username"]);
            }
          });
      }
    });
  }

  loadLogoutIframe
  logout = () => {
    console.log("logout");
    // this.loadLogoutIframe = true
    window.addEventListener("message", (event) => {
      console.log("message", event);
      if (event.data === "logout") {
        window.location.href = "/login";
      }
    });
    window.open(window.location.host === 'localhost:4200' ? 'https://localhost:4201?logout=1' : 'https://dashboard.beefeed.de?logout=1')
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.unsubscribeAll.next();
    setTimeout(() => {
      this.user = null;
      this.angularFireAuth.signOut();
      setTimeout(() => window.location.href = "/login", 1000)
    }, 100);
    // this.router.navigate(['/login'])
  };

  // CLAIMS
  setTyp(type: "FARMER" | "SELLER" | "CUSTOMER") {
    if (!this.user) throw new Error("No User to update Type");

    // this.typLoading = true
    return this.firestore
      .collection(Constants.USER_COLLECTION)
      .doc(this.user.uid)
      .set(
        {
          typ: type,
        },
        { merge: true }
      )
      .then((res) => {
        console.log("typ updated");
        this.router.navigate(["/user/steps"]);
      })
      .catch((err) => {
        console.log(err);
        this.ToastService.error({
          title: "Es ist ein Fehler aufgetreten",
          message: "Bitte erneut versuchen",
        });
      });
  }

  signInWithEmailAndPassword = (email, password, skipErrorToast = false) =>
    new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
      this.angularFireAuth
        .signInWithEmailAndPassword(email, password)
        .then((r) => {
          if (r) {
            this.initAuth();
            return resolve(r);
          }
        })
        .catch((err) => {
          console.log("loginError", err);
          if (!skipErrorToast) {
            if (err.code == "auth/user-not-found") {
              this.ToastService.error({ title: "E-Mail Adresse falsch" });
            } else if (err.code == "auth/wrong-password") {
              this.ToastService.error({ title: "Passwort falsch" });
            }
          }
          return reject(err);
        });
    });

  async signInWithToken(token) {
    console.log("jwt", token);
    const r = await this.angularFireAuth
      .signInWithCustomToken(token)
      .catch((err) => Promise.reject(err));
    if (r) {
      // this.navCtrl.setRoot('HomePage');
      return Promise.resolve(r);
    }
  }

  async register(email, password) {
    try {
      const r = await this.angularFireAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      if (r) {
        return Promise.resolve(r);
      }
      return Promise.reject(r);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loginWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("email");
    // provider.addScope('user_birthday');
    // provider.addScope('user_location');
    const res = await this.angularFireAuth
      .signInWithPopup(provider)
      .catch((err) => Promise.reject(err));

    this.firestore
      .collection(Constants.USER_COLLECTION)
      .doc(res.user.uid)
      .update({
        vorname: (res.additionalUserInfo.profile as any).first_name,
        nachname: (res.additionalUserInfo.profile as any).last_name,
        email: res.user.email,
      });
    return Promise.resolve(res);
  }

  async loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    const res = await this.angularFireAuth
      .signInWithPopup(provider)
      .catch((err) => Promise.reject(err));

    this.firestore
      .collection(Constants.USER_COLLECTION)
      .doc(res.user.uid)
      .update({
        vorname: (res.additionalUserInfo.profile as any).given_name,
        nachname: (res.additionalUserInfo.profile as any).family_name,
        picture: (res.additionalUserInfo.profile as any).picture,
        email: res.user.email,
      });
    return Promise.resolve(res);
  }

  async loginWithApple() {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    // provider.addScope('email');
    // provider.addScope('name');
    // provider.setCustomParameters({
    //   locale: 'de'
    // });
    const res = await this.angularFireAuth
      .signInWithPopup(provider)
      .catch((err) => Promise.reject(err));
    console.log(res);
    // this.firestore.collection(Constants.USER_COLLECTION).doc(res.user.uid).update({
    //   vorname: (res.additionalUserInfo.profile as any).given_name,
    //   nachname: (res.additionalUserInfo.profile as any).family_name,
    //   picture: (res.additionalUserInfo.profile as any).picture,
    //   email: res.user.email
    // })
    return Promise.resolve(res);
  }

  // async loginWithMicrosoft() {
  //   const provider = new auth.OAuthProvider('microsoft.com');
  //   // provider.addScope('mail.read');
  //   // provider.addScope('calendars.read');
  //   const res = await this.angularFireAuth.signInWithPopup(provider).catch(err => Promise.reject(err))
  //   console.log(res)
  //   // this.firestore.collection(Constants.USER_COLLECTION).doc(res.user.uid).update({
  //   //   vorname: (res.additionalUserInfo.profile as any).given_name,
  //   //   nachname: (res.additionalUserInfo.profile as any).family_name,
  //   //   picture: (res.additionalUserInfo.profile as any).picture,
  //   //   email: res.user.email
  //   // })
  //   return Promise.resolve(res)
  // }

  isLoggedIn = () =>
    new Promise((resolve) =>
      this.angularFireAuth.onAuthStateChanged((user) => resolve(user))
    );
}
