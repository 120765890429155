import { Injectable } from '@angular/core';
import { Constants, Globals } from '../Globals';
import { AngularFirestore } from '@angular/fire/firestore';
import { Friendship, User } from '../models/user.model';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import deleteUndefined from '../_shared/functions/deleteUndefined';
import { Pricelist } from '../models/order.model';
import { KeyValue } from '@angular/common';
import { Observable, ReplaySubject } from 'rxjs';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public log = ""
  public currentPricelist

  private subscriptionByFunction: any = {
    get: {},
    getCustomers: {},
    getFarmers: {},
    getByGeohash: {}
  }
  private firebaseSubscriptions = []

  constructor(
    private authService: AuthService,
    private firestore: AngularFirestore,
    private firebaseFunctions: AngularFireFunctions,
    private Globals: Globals,
    private sharedService: SharedService
  ) {
    this.authService.unsubscribeAll.subscribe(() => {
      this.sharedService.unsubscribeAll(this.subscriptionByFunction)
      this.sharedService.unsubscribeAll(this.firebaseSubscriptions)
      console.log('userService/unsubscribedAll')
    })
  }

  sortByName = (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value?.name < b.value?.name ? -1 : (b.value?.name < a.value?.name ? 1 : 0);


    sortBySortPoints = (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value?.sortPoints > b.value?.sortPoints ? -1 : (b.value?.sortPoints > a.value?.sortPoints ? 1 : 0);

  sortFriendsByName = (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value?.user?.name < b.value?.user?.name ? -1 : (b.value?.user?.name < a.value?.user?.name ? 1 : 0);

  get(userId: string = this.authService.user.uid):Observable<User> {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc<User>(userId).valueChanges().pipe(map(x => new User({ ...x, id: userId })))
    console.log('userService/get', userId)
    if (!this.subscriptionByFunction.get[userId]) {
      console.log('userService/get/callFirebase', userId)
      this.subscriptionByFunction.get[userId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc<User>(userId).valueChanges()
        .pipe(map(x => new User({ ...x, id: userId })))
        .subscribe(res => {
          this.subscriptionByFunction.get[userId].next(res)
        }))
    }
    return this.subscriptionByFunction.get[userId]
  }

  getImpressum = (userId: string) => this.get(userId)

  getByGeohash(geohash: string) {
    // return this.firestore.collection('map').doc(geohash).valueChanges().pipe(map(x => {
    //   Object.keys(x).forEach(y => {
    //     this.userMap[y] = new User({ ...x[y], id: y })
    //   })
    //   return x
    // }))

    if (!this.subscriptionByFunction.getByGeohash[geohash]) {
      console.log('userService/getByGeohash/callFirebase', geohash)
      this.subscriptionByFunction.getByGeohash[geohash] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection('map').doc(geohash).valueChanges()
        .subscribe(res => {
          this.subscriptionByFunction.getByGeohash[geohash].next(res)
        }))
    }
    return this.subscriptionByFunction.getByGeohash[geohash]
    // .pipe(map(x => x ? Object.keys(x).forEach(y => new User({ ...x[y], id: y })) : null))
  }

  getPricelists(userId: string = this.authService.user.uid) {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc<{ [key: string]: any }>('pricelists').valueChanges().pipe(tap(x => {
      if (!x || (typeof x === 'object' && Object.keys(x)?.length === 0)) {
        let id = this.Globals.uuid()
        this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc('pricelists').set({ [id]: { name: 'Öffentliche Preisliste' } })
        this.firestore.collection(Constants.USER_COLLECTION).doc(userId).set({ orderInfo: { publicPricelistId: id } }, { merge: true })
      }
    }))
  }

  getPricelist(pricelistId, userId: string = this.authService.user.uid) {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('pricelists').doc(pricelistId).valueChanges().pipe(map((x: any) => new Pricelist({ ...x, id: userId })))
  }

  updatePricelist(pricelist) {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('pricelists').update(pricelist)
  }

  getCustomer(userId: string) {
    return this.getCustomers().pipe(map(x => x[userId]))
    // return this.getCustomers().pipe(map(x => x[userId] ? x[userId] instanceof User ? x[userId] : new User(x[userId]) : null))
  }



  getCustomers(userId: string = this.authService.user.uid) {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc<{ [key: string]: Friendship }>('customers').valueChanges()
    if (!this.subscriptionByFunction.getCustomers[userId]) {
      console.log('userService/getCustomers/callFirebase', userId)
      this.subscriptionByFunction.getCustomers[userId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc<{ [key: string]: Friendship }>('customers').valueChanges()
        .subscribe(res => {
          this.subscriptionByFunction.getCustomers[userId].next(res)
        }))
    }
    return this.subscriptionByFunction.getCustomers[userId]
  }

  getFarmer(userId: string) {
    return this.getFarmers().pipe(map(x => x?.[userId]))
  }

  getFarmers(userId: string = this.authService.user.uid) {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc<{
    //   [key: string]: Friendship
    // }>('farmers').valueChanges()
    if (!this.subscriptionByFunction.getFarmers[userId]) {
      console.log('userService/getFarmers/callFirebase', userId)
      this.subscriptionByFunction.getFarmers[userId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('private').doc<{ [key: string]: Friendship }>('farmers').valueChanges()
        .subscribe(res => {
          this.subscriptionByFunction.getFarmers[userId].next(res)
        }))
    }
    return this.subscriptionByFunction.getFarmers[userId]
  }

  update(user: User) {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(user.id).set(user.toFirebase(), { merge: true })
  }

  isFriendshipSetup = () => new Promise<boolean>(async resolve => {
    this.Globals.isFarmer.then(isFarmer => {
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection((isFarmer ? 'customers' : 'farmers'), ref => ref.limit(1)).get().subscribe(res => {
        console.log('t')
        resolve(res.size == 1)
      }))
    }).catch(err => console.log('ret', err))
  })

  getUnreadFriendships() {
    if (!this.subscriptionByFunction.getUnreadFriendships) {
      this.subscriptionByFunction.getUnreadFriendships = new ReplaySubject(1)

      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc(this.Globals.user.typ == 'FARMER' || this.Globals.user.typ == 'SELLER' ? 'customers' : 'farmers').valueChanges()
        .subscribe((x: any) => {
          const res = []
          for (let i in x) {
            if (x[i].statusCode == 'PENDING') {
              res.push(new Friendship({ ...x[i], id: i }))
            }
          }
          this.subscriptionByFunction.getUnreadFriendships.next(res)
        }))
    }
    return this.subscriptionByFunction.getUnreadFriendships
  }

  async setFriendship(id, data) {
    if (await this.Globals.isFarmer) {
      return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('customers').set({ [id]: { ...deleteUndefined(data), lastEditedBy: this.authService.user.uid } }, { merge: true })
    }
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('farmers').set({ [id]: { ...deleteUndefined(data), lastEditedBy: this.authService.user.uid } }, { merge: true })
  }

  getTokens(os: 'EMAIL' | 'WEB' | 'IOS') {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('deviceTokens').get().pipe(map(r => {
      const tokens = r.data()
      if (os) {
        const res = {}
        for (const t in tokens) {
          if (tokens[t].OS === os) {
            res[t] = tokens
          }
        }
        return res
      }
      return tokens
    }))
  }

  updateTokens(data) {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('deviceTokens').set(data, { merge: true })
  }


  //search 
  search(term, typ = null) {
    console.log(term, typ)
    return this.firebaseFunctions.httpsCallable('userSearch')({ term, typ }).toPromise()
  }

}
