<div class="iosBanner" *ngIf="isIOS && !hideMenu" (click)="openInApp()">iOS App verfügbar<button>In App öffnen</button><i class="fas fa-times" (click)="isIOS=false"></i></div>
<div class="container-main" *ngIf="loading">
    <ui-header [hideBack]="true" [hideMenu]="true"></ui-header>
    <div style="text-align: center;padding-top: 40vh;">
        <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>
</div>
<router-outlet *ngIf="!loading"></router-outlet>
<div *ngIf="temp">&nbsp;</div>

<iframe [src]="logoutLink" *ngIf="authService.loadLogoutIframe" ></iframe>
<!-- <iframe [src]="logoutLink" *ngIf="authService.loadLogoutIframe" style="display: none;"></iframe> -->