import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Constants, Globals } from '../Globals';
import { Order } from '../models/order.model';
import { Category, Product } from '../models/product.model';
import { SharedService } from '../shared.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public farmers: {}
  public current = {
    friendship: null,
    friendships: {},
    pricelist: null,
    path: [],
    id: null
  }
  public shopping_cart: any
  public shoppingCartCount
  public shoppingCartPrice
  public pulseAnimation = false
  public previousShoppingCart

  private subscriptionByFunction: any = {
    get: {},
    getProduct: {},
    getPricelist: {},
    loadProductStructure: {},
    getOrderOverview: {},
  }
  private firebaseSubscriptions = []

  private tempProductData: { [key: string]: { [key: string]: Product } } = {}

  constructor(
    private authService: AuthService,
    private firestore: AngularFirestore,
    private Globals: Globals,
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router,
  ) {
    this.authService.unsubscribeAll.subscribe(() => {
      this.sharedService.unsubscribeAll(this.subscriptionByFunction)
      this.sharedService.unsubscribeAll(this.firebaseSubscriptions)
      console.log('orderService/unsubscribedAll')
    })
  }

  clearCurrent = () => this.current = {
    friendship: null,
    friendships: {},
    pricelist: null,
    path: [],
    id: null,
  }

  private getByFarmerId(orderId, farmerId) {
    if (!this.subscriptionByFunction.get[farmerId]) {
      this.subscriptionByFunction.get[farmerId] = {}
    }
    if (!this.subscriptionByFunction.get[farmerId][orderId]) {
      console.log('orderService/get/callFirebase', farmerId, orderId)
      this.subscriptionByFunction.get[farmerId][orderId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(farmerId).collection('orders').doc<Order>(orderId).valueChanges().pipe(map(x => new Order({ ...x, id: orderId })))
        .subscribe(res => {
          this.subscriptionByFunction.get[farmerId][orderId].next(res)
        }))
    }
  }

  get(orderId, farmerId = null) {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc<User>(userId).valueChanges().pipe(map(x => new User({ ...x, id: userId })))
    console.log('orderService/get', orderId)
    if (farmerId) {
      this.getByFarmerId(orderId, farmerId)
    } else {
      this.Globals.isFarmer.then(isFarmer => {
        if (isFarmer) {
          this.getByFarmerId(orderId, this.authService.user.uid)
        } else {
          // this.firestore.collectionGroup('orders', ref => ref.where('customer.id','==', this.authService.user.uid)).valueChanges().pipe(map(x => x[0] ? new Order({ ...(x[0] as any), id: orderId }) : null))
          //   .subscribe(res => obs.next(res))
        }
      })
    }
    return this.subscriptionByFunction.get[farmerId][orderId]
  }

  getUnread() {
    if (!this.subscriptionByFunction.getUnread) {
      this.subscriptionByFunction.getUnread = new ReplaySubject(1)

      this.Globals.isFarmer.then(isFarmer => {
        this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc((isFarmer) ? 'customerOrders' : 'farmerOrders').valueChanges()
          .subscribe(res => {
            if (res) {
              const data = Object.entries(res).filter(x => x[1].statusCode == 'PENDING' && ((isFarmer && x[1].readByFarmer == false) || (!isFarmer && x[1].readByCustomer == false))).map(x => new Order({ ...x[1], id: x[0] }))
              return this.subscriptionByFunction.getUnread.next(data)
            }
            return this.subscriptionByFunction.getUnread.next([])
          }))
      })
    }
    return this.subscriptionByFunction.getUnread
    // return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('orders', ref => ref
    //   .where('statusCode', '==', 'PENDING')
    //   .where(this.Globals.user.typ == 'FARMER' ? 'readByFarmer' : 'readByCustomer', '==', false)
    // ).valueChanges({ idField: 'id' }).pipe(map(x => x.map(o => new Order(o))))
  }


  create = (data, farmerId = data.farmer?.id) => {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(farmerId).collection('orders').add(data)
  }

  sortByName = (a: KeyValue<string, any>, b: KeyValue<string, any>): number =>
    a.value?.name < b.value?.name ? -1 : (b.value?.name < a.value?.name ? 1 : 0);

  sortByProductCount = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    const valA = (a.value?.productclasses ? Object.keys(a.value.productclasses).length : 0) + (a.value?.products ? Object.keys(a.value.products).length : 0)
    const valB = (b.value?.productclasses ? Object.keys(b.value.productclasses).length : 0) + (b.value?.products ? Object.keys(b.value.products).length : 0)
    return valA < valB ? 1 : (valB < valA ? -1 : 0);
  }

  loadShoppingCart = () => {
    console.log('loadShoppingCart')
    if (!this.subscriptionByFunction.loadShoppingCart && this.authService.user?.uid) {
      this.subscriptionByFunction.loadShoppingCart = new ReplaySubject(1)

      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc<{ [key: string]: any }>('shopping_cart').valueChanges().subscribe(res => {
        this.shopping_cart = res
        console.log('loadedShoppingCart', res)

        if (this.shopping_cart) {
          this.shoppingCartPrice = 0.0
          this.shoppingCartCount = 0
          let isNotBinding = false
          for (let s in this.shopping_cart) {
            this.shopping_cart[s].fullPrice = 0.0
            this.shopping_cart[s].isNotBinding = false

            for (let p in this.shopping_cart[s].products) {
              for (let u in this.shopping_cart[s].products[p].unitprices) {
                if (this.shopping_cart[s].products[p].unitprices[u]) {
                  if (this.shopping_cart[s].products[p].unitprices[u].isCalc) {
                    this.shopping_cart[s].isNotBinding = true;
                    isNotBinding = true
                  }
                  this.shopping_cart[s].fullPrice += this.shopping_cart[s].products[p].unitprices[u].shopping_cart_amount * this.shopping_cart[s].products[p].unitprices[u].price;
                  // ++this.shoppingCartCount
                  this.shoppingCartCount += this.shopping_cart[s].products[p].unitprices[u].shopping_cart_amount ?? 1
                }
              }
            }
            this.shoppingCartPrice += this.shopping_cart[s].fullPrice
          }
        }
        this.subscriptionByFunction.loadShoppingCart.next(res)
      }))
    }
    return this.subscriptionByFunction.loadShoppingCart
  }


  updateShoppingCart = (data) => {
    this.pulseAnimation = true
    setTimeout(() => this.pulseAnimation = false, 200)
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('shopping_cart').update(data)
  }

  setMergeShoppingcart = (data) => {
    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('shopping_cart').set(data, { merge: true })
  }


  updateSingleFields(orderId, order, farmerId = order.farmer?.id) {
    // this.firebaseFunctions.httpsCallable('orderUpdate')({ id: orderId, farmerId: farmerId,updated: order }).toPromise().then(async res => {})

    return this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('orders').doc(orderId).set({ ...order, updated_at: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })

  }


  getOrderOverview(userId = null, limit = null) {
    if (!this.subscriptionByFunction.getOrderOverview[userId ?? 'ALL']) {
      this.subscriptionByFunction.getOrderOverview[userId ?? 'ALL'] = new ReplaySubject(1)

      let data = {
        offeneBestellungen: null,
        zuLiefern: null,
        zuBezahlen: null,
        letzteBestellungen: null
      }

      this.Globals.isFarmer.then(isFarmer => {

        this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc(isFarmer ? 'customerOrders' : 'farmerOrders').valueChanges()
          .subscribe(orders => {
            if (orders) {
              console.log('orderSize ' + Object.keys(orders).length + ' /// ' + (this.lengthInUtf8Bytes(JSON.stringify(orders)) / 1000) + 'kb')
              data = {
                offeneBestellungen: null,
                zuLiefern: null,
                zuBezahlen: null,
                letzteBestellungen: null
              }

              for (const i of Object.keys(orders)) {
                const order = new Order({ ...orders[i], id: i })
                if (!userId || order[isFarmer ? 'customer' : 'farmer']?.id == userId) {
                  switch (order.statusCode) {
                    case 'PENDING':
                      if (!data.offeneBestellungen) {
                        data.offeneBestellungen = []
                      }

                      data.offeneBestellungen.push(order)
                      break;
                    case 'ACCEPTED':
                      if (!data.zuLiefern) {
                        data.zuLiefern = []
                      }
                      if (isFarmer) {
                        data.zuLiefern.push(order)
                      } else {

                        if (!data.offeneBestellungen) {
                          data.offeneBestellungen = []
                        }

                        data.offeneBestellungen.push(order)
                      }

                      break;
                    case 'DELIVERED':
                      if (!data.zuBezahlen) {
                        data.zuBezahlen = []
                      }

                      if (isFarmer || (order.paymentMethod == 'STRIPE' && order.isPaid == false)) {
                        data.zuBezahlen.push(order)
                      } else {

                        if (!data.letzteBestellungen) {
                          data.letzteBestellungen = []
                        }

                        data.letzteBestellungen.push(order)
                      }
                      break;
                    case 'PAYED':
                      if (!data.letzteBestellungen) {
                        data.letzteBestellungen = []
                      }
                      data.letzteBestellungen.push(order)
                      break;
                  }
                }
              }

              for (const k in data) {
                data[k]?.sort((a, b) => b.created_at - a.created_at)
              }

              if (limit) {
                for (const k in data) {
                  data[k] = data[k]?.slice(0, limit)
                }
              }
            }

            this.subscriptionByFunction.getOrderOverview[userId ?? 'ALL'].next(data)
          }))

      })
    }
    return this.subscriptionByFunction.getOrderOverview[userId ?? 'ALL']
  }

  lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
  }

  // getOrderOverview(userId = null, limit = null) {
  //   const obs = new ReplaySubject<any>(1)
  //   let wait = 4
  //   const data = {
  //     offeneBestellungen: null,
  //     zuLiefern: null,
  //     zuBezahlen: null,
  //     letzteBestellungen: null
  //   }
  //   const userDoc = this.firestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid)

  //   this.Globals.isFarmer.then(isFarmer => {
  //     userDoc.collection<Order[]>('orders', ref => this.buildRefWithUserId(userId, isFarmer, limit, ref.where('statusCode', '==', 'PENDING'))).valueChanges({ idField: 'id' }).subscribe(res => {
  //       data.offeneBestellungen = res.map(x => new Order(x))
  //       --wait
  //       if (wait <= 0) {
  //         obs.next(data)
  //       }
  //     })

  //     userDoc.collection<Order[]>('orders', ref => this.buildRefWithUserId(userId, isFarmer, limit, ref.where('statusCode', '==', 'ACCEPTED'))).valueChanges({ idField: 'id' }).subscribe(res => {
  //       data.zuLiefern = res.map(x => new Order(x))
  //       --wait
  //       if (wait <= 0) {
  //         obs.next(data)
  //       }
  //     })

  //     userDoc.collection<Order[]>('orders', ref => this.buildRefWithUserId(userId, isFarmer, limit, ref.where('statusCode', '==', 'DELIVERED'))).valueChanges({ idField: 'id' }).subscribe(res => {
  //       data.zuBezahlen = res.map(x => new Order(x))
  //       --wait
  //       if (wait <= 0) {
  //         obs.next(data)
  //       }
  //     })

  //     userDoc.collection<Order[]>('orders', ref => this.buildRefWithUserId(userId, isFarmer, limit, ref.where('statusCode', '==', 'PAYED'))).valueChanges({ idField: 'id' }).subscribe(res => {
  //       data.letzteBestellungen = res.map(x => new Order(x))
  //       --wait
  //       if (wait <= 0) {
  //         obs.next(data)
  //       }
  //     })


  //   })

  //   return obs
  // }

  // buildRefWithUserId(userId, isFarmer, limit, ref) {
  //   console.log('ref', (isFarmer ? 'customer.id' : 'farmer.id'))
  //   if (userId) { ref = ref.where((isFarmer ? 'customer.id' : 'farmer.id'), '==', userId) }
  //   if (limit) { ref = ref.limit(limit) }
  //   return ref
  // }

  loadProductStructure = (sellerId) => {
    if (!this.subscriptionByFunction.loadProductStructure[sellerId]) {
      console.log('orderService/loadProductStructure/callFirebase', sellerId)
      this.subscriptionByFunction.loadProductStructure[sellerId] = new ReplaySubject(1)

      this.firestore.collection('users').doc(this.authService.user.uid).collection('private').doc<{ [key: string]: Category }>('categories').valueChanges().subscribe(res => {
        this.sharedService.buildPathCategories(res, 'category')
        this.farmers[sellerId].structure = res
        console.log('REd', res)
        this.subscriptionByFunction.loadProductStructure[sellerId].next(res)
      })
    }
    return this.subscriptionByFunction.loadProductStructure[sellerId]
  }


  //Product
  private getProductWithPricelist(userId, productId: string, pricelistId, visibleOnly = null) {
    console.log('orderService/getProduct/callFirebase', userId, pricelistId, productId)
    this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc(userId).collection('pricelists').doc(pricelistId).collection('products').doc<Product>(productId).valueChanges()
      .pipe(map(x => new Product({ ...x, id: productId })))
      .subscribe(res => {
        if (visibleOnly) {
          res.visibleOnly = true
        }
        this.subscriptionByFunction.getProduct[userId][productId].next(res)
      }))
  }

  getProduct(userId, productId: string, pricelistId = null): Observable<any> {
    if (!this.subscriptionByFunction.getProduct[userId]) {
      this.subscriptionByFunction.getProduct[userId] = {}
    }
    if (!this.subscriptionByFunction.getProduct[userId][productId]) {
      this.subscriptionByFunction.getProduct[userId][productId] = new ReplaySubject(1)

      if (pricelistId) {
        this.getProductWithPricelist(userId, productId, pricelistId)
      } else {
        this.firebaseSubscriptions.push(this.userService.get(userId).pipe(take(1)).subscribe(res => {
          console.log('loadUser', res)
          if (res?.orderInfo?.publicPricelistId) {
            this.getProductWithPricelist(userId, productId, res.orderInfo.publicPricelistId, res.visibleOnly)
          } else {
            this.subscriptionByFunction.getProduct[userId][productId].next(null)
          }
        }))
      }

      if (this.tempProductData?.[userId]?.[productId]) {
        this.subscriptionByFunction.getProduct[userId][productId].next(this.tempProductData[userId][productId])
      }
    }
    // console.log('orderService/getProduct/done', userId, pricelistId, productId)
    return this.subscriptionByFunction.getProduct[userId][productId]
  }


  getPricelist(pricelistId, userId: string = this.authService.user.uid) {
    // return this.firestore.collection(Constants.USER_COLLECTION).doc<any>(userId).collection('pricelists').doc(pricelistId).valueChanges().pipe(map((x: any) => new Object({ ...x, id: userId })))

    console.log('orderService/getPricelist', userId)
    if (!this.subscriptionByFunction.getPricelist[userId]) {
      this.subscriptionByFunction.getPricelist[userId] = {}
    }
    if (!this.subscriptionByFunction.getPricelist[userId][pricelistId]) {
      console.log('orderService/getPricelist/callFirebase', userId, pricelistId)
      this.subscriptionByFunction.getPricelist[userId][pricelistId] = new ReplaySubject(1)
      this.firebaseSubscriptions.push(this.firestore.collection(Constants.USER_COLLECTION).doc<any>(userId).collection('pricelists').doc(pricelistId).valueChanges()
        .pipe(map((x: any) => ({ ...x, id: pricelistId })))
        .subscribe(res => {
          this.subscriptionByFunction.getPricelist[userId][pricelistId].next(res)
          const products = this.loadProductsFromCategories(res.categories)
          if (!this.tempProductData[userId]) {
            this.tempProductData[userId] = {}
          }
          products?.forEach(p => this.tempProductData[userId][p.id] = p)
        }))
    }
    return this.subscriptionByFunction.getPricelist[userId][pricelistId]
  }




  selectPathWithPath(path) {
    this.current.path = path
    this.handlePathNavigation()
  }

  selectPath(typ, level, id, body) {
    if (!(this.current.path instanceof Array)) {
      this.current.path = []
    }
    console.log(level, JSON.stringify(this.current.path.length))
    if (level < this.current.path.length) {
      this.current.path.splice(level, this.current.path.length - level)
    }
    this.current.path.push({ ...body, typ: typ, id: id })
    console.log('path', this.current.path)
    this.handlePathNavigation()
  }

  backPath(steps = 1) {
    this.current.path.pop()
    this.handlePathNavigation()
  }

  private handlePathNavigation() {
    if (this.current.path[this.current.path.length - 1]?.typ == 'product') {
      this.router.navigate([`/order/create/${this.current.friendship.user.id}/${this.current.path[this.current.path.length - 1].id}`])
    } else {
      this.router.navigate([`/order/create/${this.current.friendship.user.id}`])
    }
  }

  loadProductsFromCategories(categories) {
    if (!categories) {
      return
    }
    console.log(categories)

    const products = []
    console.log(products)
    return this._loadCategories(products, categories)
  }

  private _loadCategories(products, categories) {
    for (let k in categories) {
      if (categories[k].products) {
        products = [...products, ...Object.entries(categories[k].products).map(x => ({ ...(x[1] as any), id: x[0] }))]
      }

      if (categories[k].productclasses) {
        products = this._loadCategories(products, categories[k].productclasses)
      }
      if (categories[k].categories) {
        products = this._loadCategories(products, categories[k].categories)
      }
    }
    return products
  }
}
