import firebase from 'firebase/app'
import 'firebase/firestore'
import { Product, Unitprice } from './product.model'
import { User } from './user.model'

export class Order {
    id?:string
    lfdnr?:string
    customer: User
    farmer: User  //seller

    stripePaymentIntentId?: string

    isPaid = false

    paymentMethod = "CASH"

    payLater = false

    processDate?: Date

    //   trackingNumber?: string <-- muss noch rein

    deliverType?: string
    deliverRate = 0.0
    freeDeliveryAbove?
    

    //pickupDiscountRel = 0
    //pickupDiscountAbs = 0

    textforCustomer?: string
    textfromCustomer?: string

    option?: string
    
    readByFarmer = false //readBySeller

    order_items: OrderItem[] = []
    updated_at?: Date
    created_at?: Date

    total?: number
    isNotBinding?: boolean
    order_items_count?: number

    statusCode = "PENDING"



    constructor(o = null) {
        if (o) {
            Object.assign(this, o)

            if (o.created_at) {
                this.created_at = (o.created_at as firebase.firestore.Timestamp).toDate()
            }
            if (o.updated_at) {
                this.updated_at = (o.updated_at as firebase.firestore.Timestamp).toDate()
            }
            if (o.processDate) {
                this.processDate = (o.processDate as firebase.firestore.Timestamp).toDate()
            }

        }
    }

    toFirebase() {
        const r = JSON.parse(JSON.stringify(this))
        r.updated_at = firebase.firestore.FieldValue.serverTimestamp()
        if (this.created_at) {
            r.created_at = firebase.firestore.Timestamp.fromDate(this.created_at);
        }
        if (this.processDate) {
            r.processDate = firebase.firestore.Timestamp.fromDate(this.processDate);
            console.log('processDate', r.processDate)
        }
        return r
    }
}

export class OrderItem {
    id?: string
    unit_id: string
    amountOriginally: number = 1
    amountOrderNow: number = 1
    amount_unitprice: number = 0.0
    price_unitprice: number = 0.0

    isCalc = false
    calc_unit_id = ""
    calc_amount_unitprice: number = 0.0
    calc_price_unitprice: number = 0.0

    product: Product
    statusCode: string = "ACCEPTED"
    updated_at?: Date


    constructor(o = null) {
        if (o) {
            Object.assign(this, o)

            if (o.updated_at) {
                this.updated_at = (o.updated_at as firebase.firestore.Timestamp).toDate()
            }
        }
    }
}

export class Pricelist {
    id?: string
    name: string
    categories?: any
    unitprices: Unitprice[] = []

    constructor(o = null) {
        if (o) {
            Object.assign(this, o)
        }
    }
}