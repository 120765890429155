import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/ui-components/toast.service';
import { Constants } from 'src/app/Globals';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth'
import { AngularFireAuth } from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
import { AngularFirestore } from '@angular/fire/firestore';
import deleteUndefined from 'src/app/_shared/functions/deleteUndefined';
import { MapkitService } from 'src/app/service/mapkit.service';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare const mapkit: any;

@Injectable({
  providedIn: 'root'
})


export class AuthRegisterService {
  public user;
  public retry = true;
  public loading
  public running
  MessagingService: any;

  private errors = {
    'address': 'addresse',
    'email': 'Email',
    'name': 'Name',
    'password': 'Passwort',
    'username': 'Nutzername',
    'typ': 'Typ'
  }

  constructor(
    private angularFirestore: AngularFirestore,
    private ToastService: ToastService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private CookieService: CookieService,
    private mapkitService: MapkitService,
    private http: HttpClient,
  ) {
    if (environment.production) {
      this.mapkitService.init()
    }
  }

  public isLoggedIn() {
    return firebase.auth().currentUser ? true : false
  }

  public isAnonymous() {
    return firebase.auth().currentUser?.isAnonymous ? true : false
  }

  public save() {
    if (!this.user) {
      this.user = {}
    }

    //@TIM der das wird hier nict überschrieben wenn schon was da ist
    console.log('set', this.user)
    this.CookieService.set('reg', JSON.stringify(deleteUndefined({ ...this.user, password: undefined })));

    //DAS LOAD HIER KLAPPT ES WARUM AUCH IMMER 
    let cookie = this.CookieService.get('reg')
    console.log('load2', cookie)
  }

  public load() {
    let cookie = this.CookieService.get('reg')
    console.log('load', cookie)
    if (cookie) {
      try {
        cookie = JSON.parse(this.CookieService.get('reg'));
        for (let u in this.user) {
          if (!this.user[u] && cookie[u]) {
            this.user[u] = cookie[u]
          }
        }
      } catch (e) {

      }
    }
  }


  public register = () => new Promise(async (resolve, reject) => {
    console.log('REG', this.user)
    for (let key in this.user) {
      let route = key;
      console.log(this.isLoggedIn())
      if (this.isLoggedIn()) {
        if ((key != 'username' && key != 'id' && key != 'password' && key != 'phoneNumber' && key != 'email' && this.user[key] == null)) {
          // if (this.user[key] == null) {
          this.ToastService.error({ title: `${this.errors[key]} muss noch eingegeben werden.` })
          this.router.navigate([`/register/${route.toLowerCase()}`])
          return reject({});
        }
      } else {
        if ((key != 'username' && key != 'id' && key != 'phoneNumber' && this.user[key] == null)) {
          // if (this.user[key] == null) {
          this.ToastService.error({ title: `${this.errors[key]} muss noch eingegeben werden.` })
          this.router.navigate([`/register/${route.toLowerCase()}`])
          return reject({});
        }
      }
    }

    if (!this.user.username) {
      this.user.username = this.user.name
    }

    delete this.user.password
    if (mapkit && environment.production) {
      const geocoder = new mapkit.Geocoder({ language: "de" });

      let gc = await new Promise(resolve => {
        geocoder.lookup(`${this.user.address.street}, ${this.user.address.zip} ${this.user.address.city}, ${this.user.address.country}`, (err, g) => resolve(g));
      })

      if (this.user.address.street && gc?.['results']?.[0]) {
        this.user.address.long = gc['results'][0].coordinate.longitude
        this.user.address.lat = gc['results'][0].coordinate.latitude
      }
    }
    this.user.addresses = [this.user.address]
    if (!this.user.orderInfo) {
      this.user.orderInfo = {}
    }
    this.user.orderInfo.acceptedDeliverType = ['PICKUP']

    this.angularFirestore.collection(Constants.USER_COLLECTION).doc(firebase.auth().currentUser.uid).set(deleteUndefined(this.user), { merge: true }).then(res => {
      console.log('savedUser', res)
      resolve(res)
    })
      .catch(err => {
        this.ToastService.error({ title: 'Es ist ein Fehler aufgetreten. Bitte erneut Registrieren klicken.' });
        reject()
      })
  })

  public createUser(agb) {
    console.log('createUser')
    this.loading = true
    const auth = firebase.auth()
    if (auth.currentUser?.isAnonymous) {
      var credential = firebase.auth.EmailAuthProvider.credential(this.user.email, this.user.password);
      return auth.currentUser.linkWithCredential(credential)
        .then(res => this.createUserSuccess(res, agb))
        .catch(err => this.createUserError(err))
    }
    return this.angularFireAuth.createUserWithEmailAndPassword(this.user.email, this.user.password)
      .then(res => this.createUserSuccess(res, agb))
      .catch(err => this.createUserError(err))
  }

  private createUserSuccess(res, agb) {
    this.loading = false
    this.router.navigate(['/register/username'])
    console.log('created', res)
    this.user.id = res.user.uid
    this.save()
    this.angularFirestore.collection(Constants.USER_COLLECTION).doc(res.user.uid).set({ typ: this.user.typ, email: this.user.email, agb }, { merge: true }).then(r => {
      console.log('createdUserDocument', r)
      this.getIpaddress().then(ip => {
        this.angularFirestore.collection(Constants.USER_COLLECTION).doc(res.user.uid).collection('privateSystem').doc('register').set(
          { 'date': firebase.firestore.Timestamp.now(), 'ip': ip }
        )
      })
    }).catch(err => console.log('cannotCreateUserDocument', err))
  }

  private createUserError(err) {
    console.log('cannot register', err)
    this.loading = false
    switch (err.code) {
      case 'auth/email-already-in-use':
        this.ToastService.error({ title: 'Dieser Account wurde bereits erstellt. Bitte hier anmelden.' })
        this.router.navigate(['/login', { queryParams: { email: this.user.email } }])
        break;
      case 'auth/weak-password':
        this.ToastService.error({ title: 'Das Passwort sollte mindestens sechs Stellen lang sein.' })
      default:
        this.router.navigate(['/register/password'])
    }
  }



  getIpaddress = async () => new Promise((resolve, reject) =>
    this.http.get("https://api.ipify.org/?format=json").pipe(take(1)).subscribe((res: any) => resolve(res.ip)))

}
