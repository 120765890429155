import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Title } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { Category, Product, Productclass, ProductParent } from './models/product.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public hideCookie = false
  public isIOS
  public products = new ReplaySubject<AllProducts>(1);
  private _productList


  public editorConfig: AngularEditorConfig = {
    editable: true,
    minHeight: '80',
    placeholder: 'Hier Text eingeben...',
    toolbarHiddenButtons: [
      [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'fontName'
      ],
      [
        'fontSize',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ],
  };

  constructor(private storage: AngularFireStorage, private title: Title) { }

  public loadAllProducts(): Observable<AllProducts> {
    return this.products
  }


  unsubscribeAll(subscriptionList, stop = false) {
    if (subscriptionList) {
      if (subscriptionList.unsubscribe) {
        subscriptionList.unsubscribe()
      } else {
        for (const i in subscriptionList) {
          this.unsubscribeAll(subscriptionList[i])
        }
      }
    }
  }




  uploadFile = (file, path, typ?: 'base64') => new Observable<any>((observer) => {
    if (!file) return observer.next(0)
    const ref = this.storage.ref(path);
    let task
    if (typ && typ == 'base64') {
      task = ref.putString(file, 'data_url').catch(err => console.log('putErr', err))
      console.log(task)
      // task.percentageChanges().subscribe(res => console.log('percentage', res))
      task.then(res => {
        console.log('done', res)
        ref.getDownloadURL().pipe(take(1)).subscribe(url => {
          observer.next({ done: true, percentage: 1, url, ref })
        })
      }).catch(err => console.log('errDone', err))
    } else {
      task = this.storage.upload(path, file, {});
      task.snapshotChanges().pipe(finalize(() =>
        ref.getDownloadURL().pipe(take(1)).subscribe(url => {
          observer.next({ done: true, percentage: 1, url, ref })
        })
      )).subscribe(res =>
        observer.next({ done: false, percentage: res.bytesTransferred / res.totalBytes })
      )
    }
  })


  buildPathCategories(cs, typ, currentPath = [], addToProductList = false) {
    if (cs) {
      for (let k of Object.keys(cs)) {
        if (typeof cs[k] != 'object') {
          break;
        }
        // cs[k].path = [...currentPath, { ...cs[k], typ, id: k }]
        cs[k].path = currentPath
        if (cs[k].products) {
          this.buildPathProducts(cs[k].products, [...currentPath, { ...cs[k], typ, id: k }], addToProductList)
        }
        if (cs[k].productclasses) {
          this.buildPathCategories(cs[k].productclasses, 'productclass', [...currentPath, { ...cs[k], typ, id: k }], addToProductList)
        }
        if (cs[k].subcategories) {
          this.buildPathCategories(cs[k].subcategories, 'category', [...currentPath, { ...cs[k], typ, id: k }], addToProductList)
        }

        switch (typ) {
          case 'category':
            cs[k] = new Category({ ...cs[k], id: k })
            break;
          case 'productclass':
            cs[k] = new Productclass({ ...cs[k], id: k })
            break;
          default:
            cs[k] = new ProductParent({ ...cs[k], id: k })
            break;
        }
      }
    }
  }

  private buildPathProducts(ps, currentPath, addToProductList = false) {
    for (let k of Object.keys(ps)) {
      // ps[k].path = [...currentPath, { ...ps[k], typ: 'product', id: k }]
      ps[k].path = currentPath
      ps[k] = new Product({ ...ps[k], id: k })
      if (addToProductList) {
        this._productList.push(ps[k])
      }
    }
  }

  getProductsListed(cs) {
    this._productList = []
    this.buildPathCategories(cs, 'category', [], true)
    return this._productList
  }

  setTitle = (title: string) => {
    this.title.setTitle(`${title} | beefeed`)
  }
}

export class AllProducts {
  categories: any[]
  productclasses: any[]
  products: any[]
}

