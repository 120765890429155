import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
declare const mapkit: any;

@Injectable({
  providedIn: 'root'
})
export class MapkitService {
  private isInitialized = false

  constructor(private firebaseFunctions: AngularFireFunctions) { }

  init = () => new Promise((resolve) => {
    console.log('prom', this.isInitialized)
    if (!this.isInitialized) {
      this.isInitialized = true
      this.getToken().then(token => {
        console.log('mapToken', token)
        mapkit.init({
          authorizationCallback: done => done(token),
          language: "de"
        })
        resolve(true)
      }).catch(err => console.log('mapkitTokenError', err))
    } else {
      console.log('no')
      resolve(true)
    }
  })

  getToken() {
    return this.firebaseFunctions.httpsCallable('getMapsToken')({}).toPromise()
  }
}
