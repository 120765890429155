import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { mergeMapTo } from 'rxjs/operators';
import { Constants } from './Globals';
import { ToastService } from './ui-components/toast.service';
import firebase from 'firebase/app'
import 'firebase/messaging';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth/auth.service';

@Injectable()
export class MessagingService {
  private listener;
  // messaging = firebase.messaging()
  currentMessage = new BehaviorSubject(null)
  // private messaging;
  private token;
  private notSupported = false
  public get showIcon(): boolean {
    return (this.token != undefined || this.notSupported) ? false : true;
  }

  constructor(private angularFirestore: AngularFirestore, private afMessaging: AngularFireMessaging, private authService: AuthService, private ToastService: ToastService) {
    // try {
    //   this.messaging = firebase.messaging();
    //   // console.log('SET')
    // } catch (e) {
    //   console.log('Unable to Instantiate Firebase Messaing', e);
    // }
  }


  updateToken(token, enabled = true) {
    // console.log('UpDATE')
    if (this.authService.user) {
      this.angularFirestore.collection(Constants.USER_COLLECTION).doc(this.authService.user.uid).collection('private').doc('deviceTokens').set({
        [token]: {
          device: navigator.userAgent,
          OS: "WEB",
          enabled
        }
      }, { merge: true })
        .then(result => {
          this.token = token;
          console.log('messagingService/updateToken', result)
        })
        .catch(error => { console.error('messagingService/updateToken', error) })
    }
  }

  getPermission() {
    const u = firebase.auth().currentUser
    if (u && !u.isAnonymous) {
      try {
        this.afMessaging.requestPermission
          .pipe(mergeMapTo(this.afMessaging.tokenChanges))
          .subscribe(
            (token) => {
              // console.log('messagingService/getPermission', token);
              this.updateToken(token);
            },
            (error) => {
              console.log('messagingService/getPermission ERROR NO PERMISSION');
              if (error.code == 'messaging/unsupported-browser') {
                this.notSupported = true;
              }
            }
          );
      } catch (e) {
        console.log('messagingService/getPermission ERROR_P', e)
      }
    }
  }

  receiveMessage() {
    // console.log('messagingService/receiveMessage', this.listener)
    if (!this.listener) {

      this.listener = this.afMessaging.messages
        .subscribe((message) => {
          // console.log('messagingService/receiveMessage', message)
          if (!message['notification']['title']) {
            this.ToastService.info({ title: message['notification']['body'] });
          } else {
            this.ToastService.info({ title: message['notification']['title'], message: message['notification']['body'] });
          }
          this.currentMessage.next(message);
        }, error => console.log('receiveMessageError', error));

      // this.afMessaging.messaging.subscribe(res => {
      //   console.log(res)
      // })
    }
  }

  disableToken() {
    if (this.token) {
      this.updateToken(this.token, false)
    }
  }
}